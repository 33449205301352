.header {
    position:fixed; 
    top: 0; 
    left: 0; 
    width: 100%;
    max-width: 100%;
  
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
   

    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000000;
}


.logo{
    width: 70px;
    height: auto;
}

.Apexlogo{
    width: 200px;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        padding: 15px 20px;
    }

    .logo, .Apexlogo {
        width: 50px; 
    }

    .Apexlogo {
        width: 150px;
    }
}

@media (max-width: 480px) {
    .header {
        flex-direction: column;
        padding: 10px 15px;
    }

    .logo, .Apexlogo {
        width: 40px; 
    }

    .Apexlogo {
        width: 120px;
    }
}