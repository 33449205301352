.button-image {
    width: 300px;
}

.button-with-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 30px;
    padding-bottom: 70px;
}

.button-text {
    color: white;
    padding: 20px;
}

@media (min-width: 1025px) and (max-width: 1368px) {
    .button-with-image {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 20px;
        padding-bottom: 60px;
    }

    .button-image {
        width: 250px;
    }

    .button-text {
        font-size: 1.2rem;
    }
}


@media (max-width: 768px) {
    .button-with-image {
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 30px;
        padding-bottom: 70px;
    }

    .button-image {
        max-width: 100%;
    }

    .button-text {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .button-with-image {
        padding: 30px;
    }

    .button-image {
        max-width: 100%;
    }

    .button-text {
        font-size: 0.9rem;
    }
}
