.footer {
    background-color: #000000; 
    color: white; 
    padding: 80px;
    text-align:right;
  }
  
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-logo {
    width: 300px; 
    margin-bottom: 10px;
  }
  
  .footer-heading {
    font-size: 1.5rem;
  }
  
  .footer-info {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }

  .footer-link {
    color: #ffffff;
    text-decoration: underline;
  }
  
  .footer-link:hover {
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-logo {
      width: 150px;
      margin-bottom: 20px;
    }
  
    .footer-info {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .footer-content {
      padding: 10px;
    }
  
    .footer-logo {
      width: 120px;
    }
  
    .footer-heading {
      font-size: 1.2rem;
    }
  
    .footer-info {
      font-size: 0.9rem;
    }
  }.footer {
    background-color: #000000; 
    color: white; 
    padding: 80px;
    text-align:right;
  }
  
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-logo {
    width: 300px; 
    margin-bottom: 10px;
  }
  
  .footer-heading {
    font-size: 1.5rem;
  }
  
  .footer-info {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }

  .footer-link {
    color: #ffffff;
    text-decoration: underline;
  }
  
  .footer-link:hover {
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-logo {
      width: 150px;
      margin-bottom: 20px;
    }
  
    .footer-info {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .footer-content {
      padding: 10px;
    }
  
    .footer-logo {
      width: 120px;
    }
  
    .footer-heading {
      font-size: 1.2rem;
    }
  
    .footer-info {
      font-size: 0.9rem;
    }
  }